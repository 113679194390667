.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 10rem;
    height: 3rem;
    filter: opacity(0.8) drop-shadow(0 0 0   rgb(0, 0, 0));
}

.header-menu {
    text-justify: bold;
    list-style: none;
    display: flex;
    gap: 2rem;
    color: rgb(255, 255, 255);
    text-shadow: rgb(0, 0, 0) 3px 0 10px;
}


.header-menu>li:hover {
    cursor: pointer;
    color: rgb(255, 154, 1);
    text-shadow: var(--orange) 3px 0 10px;
}

@media screen and (max-width: 768px) {
    .header>:nth-child(2) {
        position: fixed;
        right: 2rem;
        z-index: 99;
    }

    .header-menu {
        flex-direction: column;
        background-color: var(--appColor) ;
        padding: 2rem;
    }
}