.plans-container {
    margin-top: -4rem;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    position: relative;
}

.programs-header {
    margin-bottom: 5.5rem;
}

.plans {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem; 
}

.plan {
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap: 2rem;
    padding: 1.5rem;
    width: 15rem;
}

.plan>svg {
    fill: var(--orange);
    width: 2rem;
    height: 2rem;
}

.plan:nth-child(2) {
    background: var(--planCard);
    transform: scale(1.1);
}


.plan>:nth-child(3) {
   font-size: 2rem;
   font-weight: bold;
}

.plan>:nth-child(5) {
    font-size: 0.8rem;
 }

 .features {
     display: flex;
     flex-direction: column;
     gap: 1rem;
 }

 .feature {
     display: flex;
     align-items: center;
     gap: 1rem;
 }

 .feature>img {
     width: 1rem;
 }

 .plans-blur-1 {
     width: 23rem;
     height: 23rem;
     margin-top: 10rem;
     left: 5rem;
} 

.plans-blur-2 {
    width: 32rem;
    height: 23rem;
    right: 0rem; 
}

 .plans>:nth-child(2)>svg {
     fill: white;
 }

 .plans>:nth-child(2)>button {
    color: rgba(255, 169, 8, 0.973)
}


.btn:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.795), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.plan {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
}

.plan:hover {
    box-shadow: 0 12px 16px 0 rgb(255, 255, 255), 0 17px 50px 0 rgba(0,0,0,0.19);
}

.header-menu {
    text-transform: uppercase;
}

@media screen and (max-width: 768px) {
    .plans {
        flex-direction: column;
    }

    .plan:nth-child(2) {
        transform: none;
    }
}

